import { SearchState } from "react-instantsearch-core"

import { AllCategories } from "~/hooks/useCategories"
import {
  addHashToUrl,
  getCategoriesTreePart,
  getDietPart,
  getDifficultyPart,
  getIngredientsPart,
  getTypePart,
  getVerticalPart,
  parseHash,
} from "~/templates/Category/utils/urlUtils"

const createVerticalURL = (state: any, CATEGORIES: any, baseSlug: string) => {
  let url = "/" + baseSlug + "/"
  const hashPart = []

  const { mainPart, hashPart: verticalPart } = getVerticalPart(state, true)
  url += mainPart
  hashPart.push(verticalPart)

  const { hashPart: ingredientsPart } = getIngredientsPart(state)
  hashPart.push(ingredientsPart)

  const { hashPart: categoriesPart } = getCategoriesTreePart(state, CATEGORIES)
  hashPart.push(categoriesPart)

  const { hashPart: typePart } = getTypePart(state)
  hashPart.push(typePart)

  const { hashPart: difficultyPart } = getDifficultyPart(state)
  hashPart.push(difficultyPart)

  const { hashPart: dietPart } = getDietPart(state)
  hashPart.push(dietPart)

  return url ? `${addHashToUrl(url, hashPart)}` : ""
}

function searchStateToVerticalUrl(
  searchState: any,
  CATEGORIES: any,
  intl: any
) {
  //IF NO VERTICAL => GO TO RESEARCH
  if (!searchState.refinementList?.vertical) {
    return `/${intl.formatMessage({ id: "path:search" })}/`
  }

  const baseUrl = intl.formatMessage({ id: "path:recipes" })

  return createVerticalURL(searchState, CATEGORIES, baseUrl)
}

const verticalUrlToSearchState = (
  search: string,
  pathname: string,
  hash: string,
  CATEGORIES: AllCategories
) => {
  const pathnameParts = pathname.split("/").filter((part: string) => part != "")

  let searchState: SearchState = {
    refinementList: {
      vertical: [pathnameParts[pathnameParts.length - 1]],
    },
  }

  if (hash) {
    searchState = parseHash(hash, CATEGORIES, searchState)
  }

  return searchState
}

export { createVerticalURL, searchStateToVerticalUrl, verticalUrlToSearchState }
