import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import {
  formatBannerToDisplay,
  PrismicBanners,
} from "../../components/Banner/utils"
import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import SEO from "~/components/Seo/Seo"
import { PageContextCategory } from "~/templates/Category/Category"
import FilterPage from "~/templates/Category/FilterPage"
import {
  createVerticalURL,
  searchStateToVerticalUrl,
  verticalUrlToSearchState,
} from "~/templates/Category/utils/verticalUrlUtils"

interface PropTypes extends TemplateDefaultPropTypes {
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextCategory
  data: { prismicBanner: PrismicBanners | null }
}

function SearchPage({
  location,
  pageContext,
  data,
  path,
  navigate,
}: PropTypes) {
  const pathnameParts = path.split("/")
  const vertical = [pathnameParts[pathnameParts.length - 1]]
  const intl = useIntl()

  const refreshTitle = (
    searchState: any,
    CATEGORIES: any,
    isSSG: boolean
  ): {
    pageTitle: string
    description?: string
  } => {
    const vertical = isSSG
      ? pageContext.pageTitle
      : searchState.refinementList?.vertical[0]

    const pageTitle = `${intl.formatMessage({
      id: "category/text:all-recipes-chefclub",
    })}`.replace(
      "%s",
      vertical ? ` ${intl.formatMessage({ id: `brand:${vertical}` })}` : ""
    )

    const description = vertical
      ? `${intl.formatMessage({ id: `vertical/description:${vertical}` })}`
      : intl.formatMessage({
          id: "category/text:default-description",
        })

    return {
      pageTitle,
      description,
    }
  }
  const bannerToDisplay = data.prismicBanner
    ? formatBannerToDisplay(data.prismicBanner)
    : null
  return (
    <>
      <SEO title={"Univers" + (vertical ? " - " + vertical : "")} />
      <FilterPage
        pageContext={pageContext}
        createURL={createVerticalURL}
        urlToSearchState={verticalUrlToSearchState}
        searchStateToUrl={searchStateToVerticalUrl}
        path={path}
        navigate={navigate}
        location={location}
        refreshTitle={refreshTitle}
        baseSlug={intl.formatMessage({ id: "path:recipes" })}
        bannerToDisplay={bannerToDisplay}
      />
    </>
  )
}
export default withTemplate(SearchPage)

//You can find the fragment definition in src/components/Banner/Banner.tsx
export const query = graphql`
  query VerticalBanners($prismicLanguage: String) {
    prismicBanner(
      data: { banner_type: { eq: "filtered_recipes_page" } }
      lang: { eq: $prismicLanguage }
    ) {
      ...Banner
    }
  }
`
